<template>
  <div class="min-h-full h-screen flex-col pt-16 bg-brandBlue overflow-hidden">
    <div class="h-full grid content-center place-items-center">      
      <div class="w-1/4 md:w-1/12">
        <img src="/img/brand/g-400-white.png" alt="" />
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'SplashView',
  components: {
    
  },
  mounted(){
    console.log("Come on bro, get out of here")
  }
}
</script>
